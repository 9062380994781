import React from "react";
import printJS from "print-js";

const PrintUsingPrintJs = () => {
    
  const handlePrintClick = () => {
    printJS({
      printable: "contentToPrint",
      type: "html",
      targetStyles: ["*"],
      silent: true,
    });
    console.log("Button Clicked");
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <button
          id="btnPrint"
          className="btn btn-primary m-1"
          onClick={handlePrintClick}
        >
          Print
        </button>
      </div>

      <div style={{ display: "none" }}>
        <div id="contentToPrint">
          <table
            style={{
              padding: "10px",
              marginTop: "10px",
              margin: "0 auto",
              width: "57mm",
              background: "#fff",
              border: "1px solid #eee",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <table style={{ width: "100%", fontSize: "10px" }}>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            style={{
                              width: "50%",
                              textAlign: "center",
                              WebkitAlignContent: "center",
                              alignContent: "center",
                              padding: "5px",
                              display: "block",
                              margin: "0 auto",
                            }}
                            src="https://khateraho.com/assets/mobile/img/khateraho_black.png"
                            alt="Logo"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p style={{ padding: "5px 0", margin: "0" }}>
                            GST Number : 4910487129047124
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: "100%", fontSize: "10px" }}>
                    <tbody>
                      <tr>
                        <td>
                          Date : <span>12 July 2023</span>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Time : <span>2:30 pm</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Table #: <span>3</span>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Bill # : <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2">
                          <p
                            style={{
                              textAlign: "center",
                              padding: "5px 2px",
                              margin: "0",
                              fontSize: "medium",
                            }}
                          >
                            Original Receipt
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: "100%", fontSize: "12px" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            fontSize: "11px",
                            textTransform: "uppercase",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            verticalAlign: "middle",
                          }}
                        >
                          Item
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "center",
                            fontSize: "11px",
                            textTransform: "uppercase",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            verticalAlign: "middle",
                          }}
                        >
                          Qty
                        </th>
                        <th
                          style={{
                            width: "20%",
                            textAlign: "center",
                            fontSize: "11px",
                            textTransform: "uppercase",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            verticalAlign: "middle",
                          }}
                        >
                          Rate
                        </th>
                        <th
                          style={{
                            width: "25%",
                            textAlign: "right",
                            fontSize: "11px",
                            textTransform: "uppercase",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            verticalAlign: "middle",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "left",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            width: "60%",
                            minWidth: "60%",
                            maxWidth: "60%",
                            wordBreak: "break-all",
                          }}
                        >
                          Chocolate milkshake frappe
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;200.00
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;200.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "left",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            width: "60%",
                            minWidth: "60%",
                            maxWidth: "60%",
                            wordBreak: "break-all",
                          }}
                        >
                          Non-Veg Focaccoa S/W
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          2
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;300.00
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;600.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "left",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            width: "60%",
                            minWidth: "60%",
                            maxWidth: "60%",
                            wordBreak: "break-all",
                          }}
                        >
                          Classic mojito
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;800.00
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;800.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "left",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            width: "60%",
                            minWidth: "60%",
                            maxWidth: "60%",
                            wordBreak: "break-all",
                          }}
                        >
                          Non-Veg Ciabatta S/W
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;500.00
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;500.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            borderTop: "1px solid black",
                            paddingRight: "12px",
                          }}
                        >
                          Subtotal
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            borderTop: "1px solid black",
                          }}
                        >
                          &#8377;12112.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            paddingRight: "12px",
                          }}
                        >
                          CGST
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "10px",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;10.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            fontSize: "10px",
                            textAlign: "right",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            paddingRight: "12px",
                          }}
                        >
                          SGST
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "10px",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                          }}
                        >
                          &#8377;10.00
                        </td>
                      </tr>
                      <tr>
                        <th
                          colSpan="3"
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            borderTop: "1px dashed black",
                            borderBottom: "1px dashed black",
                            paddingRight: "12px",
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            verticalAlign: "bottom",
                            padding: "3px 0",
                            borderTop: "1px dashed black",
                            borderBottom: "1px dashed black",
                          }}
                        >
                          &#8377;12132.00
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ width: "100%", fontSize: "11px" }}>
                    <tbody>
                      <tr>
                        <td>
                          Paid by : <span>CASH</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p
                            style={{
                              textAlign: "center",
                              padding: "5px 2px",
                              margin: "0",
                            }}
                          >
                            Thank you for your visit!
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PrintUsingPrintJs;
