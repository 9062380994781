import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ThermalPrint from "./components/ThermalPrint";
import PrintUsingPrintJs from "./components/PrintUsingPrintJs";
// import Notification from "./components/Notification";
// import Print from "./components/Print";

function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            {/* <Route path="/" element={<Notification />}></Route> */}
            <Route path="/" element={<PrintUsingPrintJs />}></Route>
            {/* <Route path="/" element={<ThermalPrint />}></Route> */}
            {/* <Route path="/" element={<Print />}></Route> */}
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
